import * as Yup from 'yup';

const SignInValidation = () =>
  Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().required('Password is required'),
  });

const ForgotPasswordValidation = () =>
  Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
  });

const ResetPasswordValidation = () =>
  Yup.object().shape({
    code: Yup.string().required('Required'),
    password: Yup.string()
      .required('Password is required')
      .min(6, 'Too Short!'),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Passwords must match'
    ),
  });
const RegistrationValidation = () =>
  Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    lastName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    orgName: Yup.string().required('Required').min(2, 'Too Short!'),
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string()
      .required('Password is required')
      .min(6, 'Too Short!'),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Passwords must match'
    ),
  });
const RegistrationConfirmationValidation = () =>
  Yup.object().shape({
    code: Yup.string().required('Required'),
  });
export {
  SignInValidation,
  ForgotPasswordValidation,
  ResetPasswordValidation,
  RegistrationValidation,
  RegistrationConfirmationValidation,
};
