import React from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { getOr } from 'lodash/fp';

import { useTranslation } from 'react-i18next';
import CustomInputField from '../custom/CustomInputField';
import { ForgotPasswordValidation } from './validation';
import { auth, alert, businesses } from '../../state';

const ForgotPasswordForm = ({ forgotPassword, setAlert, setUser }) => {
  const { t } = useTranslation();
  const merchant = useSelector(businesses.selectors.selectMerchant);
  return (
    <Formik
      enableReinitialize
      validationSchema={ForgotPasswordValidation}
      initialValues={{
        email: '',
        businessId: merchant.id,
      }}
      onSubmit={async (values) => {
        const result = await forgotPassword(values);
        const successMsg = getOr('', ['payload', 'message'], result);
        const error = getOr('', ['error', 'message'], result);
        if (error) {
          setAlert({ message: error, severity: 'error' });
        }
        if (!error) {
          setUser(values);
          setAlert({ message: successMsg, severity: 'info' });
          navigate('/reset-password');
        }
      }}
    >
      {({ isSubmitting, handleSubmit }) => {
        return (
          <Form>
            <Box pb={2}>
              <CustomInputField
                customInput={Field}
                component={TextField}
                trim
                fullWidth
                required
                type="email"
                label="E-mail"
                name="email"
                variant="outlined"
              />
            </Box>
            <Box>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={handleSubmit}
                size="large"
              >
                {isSubmitting ? (
                  <CircularProgress size={24} />
                ) : (
                  t('forgotPassword')
                )}
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

ForgotPasswordForm.propTypes = {
  forgotPassword: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  forgotPassword: (payload) => dispatch(auth.actions.forgotPassword(payload)),
  setAlert: (payload) => dispatch(alert.actions.open(payload)),
  setUser: (payload) => dispatch(auth.actions.setUser(payload)),
});

export default connect(null, mapDispatchToProps)(ForgotPasswordForm);
